<template>
    <div>
        <FilterModal
            @reset="resetFilter"
            @filter="getFilterData"
            :isLoading="isLoading"
        >
            <template v-slot:filters>
                <AccountRoleFilter
                    ref="accountRoleFilter"
                    v-model:titleType="filterPayload.titleType"
                    v-model:manCode="filterPayload.manCode"
                    v-model:role="filterPayload.role"
                    v-model:bgCode="filterPayload.bgCode"
                />
                <DateRangeTextbox
                    startName="startDate"
                    endName="endDate"
                    max="today"
                    min="default"
                    :required="true"
                    :tipObj="dateFilterTips"
                    :isOnlyMonth="true"
                    :hasLimit="true"
                    :limitDays="{ year: 1 }"
                    title="工作月"
                    v-model:startDate="filterPayload.startDate"
                    v-model:endDate="filterPayload.endDate"
                />
                <Dropdown
                    name="relationMode"
                    :disabled="true"
                    :title="filterTitles.relationMode"
                    :options="filterOptions.relationMode"
                    v-model="filterPayload.relationMode"
                />
            </template>
        </FilterModal>
    </div>
</template>
<script>
import Dropdown from '@/components/Dropdown.vue'
import DateRangeTextbox from '@/components/DateRangeTextbox.vue'
import FilterModal from '@/containers/FilterModal.vue'
import AccountRoleFilter from '@/containers/AccountRoleFilter.vue'
import { underlingFilterTitles } from '@/assets/javascripts/commission/setting'
import filterDefault from '@/assets/json/filterDefault.json'

export default {
    name: 'UnderlingFilter',
    emits: [
        'show-data-table',
        'update:filterPayloadProp',
        'update:filterLogProp'
    ],
    components: {
        DateRangeTextbox,
        Dropdown,
        FilterModal,
        AccountRoleFilter
    },
    props: {
        filterPayloadProp: {
            type: Object,
            default: function () {
                return {
                    titleType: '',
                    manCode: '',
                    role: '',
                    bgCode: ''
                }
            }
        },
        filterLogProp: {
            type: Object,
            default: function () {
                return {}
            }
        },
        isLoading: {
            type: Boolean,
            default: false
        }
    },
    methods: {
        getFilterData: function () {
            /*
             ** 若送api的payload有異動 要重設進階篩選
             ** 若api的payload無異動 只需做進階篩選
             */
            if (
                !this.$isSameValue(
                    this.perviousFilterPayload,
                    this.filterPayload
                )
            ) {
                this.perviousFilterPayload = this.$cloneDeepValue(
                    this.filterPayload
                )
                this.$emit('show-data-table')
                this.filterLog = this.$getFilterLog(this.filterPayload)
                this.filterLog.startDate.label = this.startDateLabel
                this.filterLog.endDate.label = this.endDateLabel
            }
        },
        resetFilter: function () {
            this.filterPayload = {
                manCode: this.filterPayload?.manCode || '',
                bgCode: this.filterPayload?.bgCode || '',
                startDate: this.defaultDate.start,
                endDate: this.defaultDate.end,
                /* 控制顯示選項用 */
                ...filterDefault.underline
            }
            // includes mType, manCode(payload), role, bgCode(payload)
            this.$refs.accountRoleFilter.resetFilter()
        }
    },
    computed: {
        filterOptions: function () {
            return {
                relationMode: [
                    {
                        label: '輔導線',
                        value: 'relation'
                    }
                ]
            }
        },
        startDateLabel: function () {
            /* 日改月，值需把日trim掉 */
            const date = this.filterPayload.startDate
            const period = date.slice(0, 7)
            return period
        },
        endDateLabel: function () {
            /* 日改月，值需把日trim掉 */
            const date = this.filterPayload.endDate
            const period = date.slice(0, 7)
            return period
        },
        minEndDate: function () {
            const date = this.$getDate(0, 0, 0, this.filterPayload.startDate)
            return this.$formatDate(date)
        },
        dateFilterTips: function () {
            const tips = `
            <div class="flex"><span>・</span> 以「工作月(迄)」當下組織為準</div>
            <div class="flex"><span>・</span> 為提供較佳的效能體驗，供查詢的資料區間僅限12個月</div>
            <div class="flex"><span>・</span> 年終獎金發放請參考年終獎金公文</div>`
            return { start: tips, end: tips }
        },
        defaultDate: function () {
            return {
                start: this.$formatDate(
                    this.$getFirstDate(this.$getDate(0, -2))
                ),
                end: this.$formatDate(this.$getLastDate(this.$getDate(0, -1)))
            }
        }
    },
    watch: {
        filterPayload: {
            handler() {
                this.$emit('update:filterPayloadProp', this.filterPayload)
            },
            deep: true
        },
        filterLog: {
            handler() {
                this.$emit('update:filterLogProp', this.filterLog)
            },
            deep: true
        }
    },
    data() {
        return {
            allOption: [
                {
                    label: '全部',
                    value: ''
                }
            ],
            filterTitles: underlingFilterTitles,
            perviousFilterPayload: {},
            filterPayload: this.filterPayloadProp,
            filterLog: this.filterLogProp
        }
    },
    async mounted() {
        this.$nextTick(() => {
            const accountRoleTitles = this.$refs.accountRoleFilter.filterTitles
            this.filterTitles = {
                ...accountRoleTitles,
                ...underlingFilterTitles,
                _seq: [...accountRoleTitles._seq, ...underlingFilterTitles._seq]
            }
        })
        this.resetFilter()
        // this.$nextTick(() => {
        //     this.getFilterData()
        // })
    }
}
</script>
<style lang="scss" scoped></style>
